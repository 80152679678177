<template>
  <div class="position-relative banner-cont">
    <div
      class="video-wrapper shape-container"
      :style="`height: ${height}px !important;`"
    >
      <!-- Background Video -->
      <video
        v-if="video"
        playsinline
        autoplay
        muted
        loop
      >
        <source
          :src="video"
          type="video/mp4"
        >
        Your browser does not support the video tag.
        <track
          label="English"
          kind="captions"
          srclang="en"
          :src="video"
          default
        >
        <track
          label="Español"
          kind="captions"
          srclang="es"
          :src="video"
        >
      </video>

      <!-- Background Image -->
      <b-img-lazy
        v-if="backImage"
        :src="backImage"
        class="backgroundImage"
        alt="Background Image"
      />

      <div
        class="title container mt-5"
        style="position: relative"
      >

        <!-- Logo Image -->
        <b-img-lazy
          v-if="image"
          :src="image"
          alt="Logo Limmat"
          :class="solutions ? 'img-fluid logo-image mb-2' : 'img-fluid mb-2'"
        />

        <!-- Title -->
        <h3 v-if="title">
          {{ title }}
        </h3>

        <!-- Subtitle -->
        <h4
          v-if="subtitle"
          style="text-transform: uppercase; font-size: 20px"
        >
          {{ subtitle }}
        </h4>

        <!-- Carousel Home Subtitles -->
        <b-carousel
          v-if="data"
          id="carousel"
          v-model="slide"
          :interval="9000"
          no-animation
        >
          <template v-for="item in data">
            <div
              :key="item.title"
              style="background-color: white"
            >
              <b-carousel-slide>
                <template #img>
                  <h2>
                    {{ item.text }}
                  </h2>
                </template>
              </b-carousel-slide>
            </div>
          </template>
        </b-carousel>

        <b-button
          v-if="buttonText"
          class="mt-3"
          style="text-transform: uppercase;"
          href="home"
          variant="primary"
          pill
        >
          {{ buttonText }}
        </b-button>

      </div>
    </div>
  </div>
</template>

<script>
import {
  BImgLazy,
  BCarousel,
  BCarouselSlide,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Banner',
  components: {
    BCarousel,
    BCarouselSlide,
    BImgLazy,
    BButton,
  },
  props: {
    video: {
      type: String,
      default: null,
      required: false,
    },
    backImage: {
      type: String,
      default: null,
      required: false,
    },
    buttonText: {
      type: String,
      default: null,
      required: false,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    subtitle: {
      type: String,
      default: null,
      required: false,
    },
    image: {
      type: String,
      default: null,
      required: false,
    },
    data: {
      type: Array,
      default: null,
      required: false,
    },
    height: {
      type: String,
      default: null,
      required: false,
    },
    solutions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slide: 0,
    }
  },
}
</script>

<style scoped>

  video {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height:100%;
    filter:brightness(0.6);
  }

  .backgroundImage {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height:100%;
    filter:brightness(0.6);
  }

  .video-wrapper {
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    font-size: 35px !important;
  }

  h3 {
    color: #ff8f00 !important;
    text-transform: uppercase;
    font-size: 40px !important;
  }

  h4 {
    color: white !important;
  }

  @media (max-width: 700px){
    h3 {
      font-size: 33px !important;
    }

    h2 {
      font-size: 27px !important;
    }
  }

  .logo-image {
    width: 80px;
    max-width: 80%;
    height: auto;
  }

</style>
